@mixin grid() {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--gap);

  @supports (grid-area: auto) {
    display: grid;
  }
}

@mixin gridAuto() {
  flex-direction: row;
  flex-wrap: wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
    grid-template-rows: min-content;
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
