.table {
  color: var(--foreground);

  tbody {
    hyphens: auto;
  }
}

[data-theme="dark"],
[data-theme="high"] {
  .table {
    &-danger,
    &-warning {
      color: var(--background);

      a {
        color: var(--background);
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .table {
    &-danger,
    &-warning {
      color: var(--background);

      a {
        color: var(--background);
      }
    }
  }
}

:not(.table-responsive) > .table > thead {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: var(--background);
}
