html {
  height: 100%;
}

body {
  height: 100%;
  color: var(--foreground);
  background: var(--background);
  margin-top: 0;
}

a {
  color: var(--accent);

  &:hover {
    color: var(--accent-higher);
  }
}

img,
source {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.btn-link {
  color: currentcolor;
}

/* Tabellen mit breiten Inhalt nicht zerstören */
.start td:nth-child(5),
.detail td:nth-child(4) {
  overflow: auto;
  max-width: 40rem;
}

.wiki {
  background: var(--background);
  color: var(--foreground);
  box-shadow: 0 0 0.3rem var(--middleground);
  border-radius: $border-radius;
  overflow: auto;
  max-width: 90vw;
  word-break: break-all;
  white-space: pre-wrap;
  padding: var(--gap);
}

footer {
  width: 100%;
  font-size: 0.85rem;
  padding: 0.5rem 0;
}
