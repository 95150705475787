:root {
  --gap: 1rem;
  --col: 15rem;
  --slate-gray-0: #0e0e0f;
  --slate-gray-1: #1f2023;
  --slate-gray-2: #303137;
  --slate-gray-3: #42434d;
  --slate-gray-4: #555762;
  --slate-gray-5: #696b78;
  --slate-gray-6: #7e808e;
  --slate-gray-7: #9496a3;
  --slate-gray-8: #acadb8;
  --slate-gray-9: #c4c5cd;
  --slate-gray-10: #dddde2;
  --slate-gray-11: #f6f6f7;
  --green-0: #0e190f;
  --green-1: #132d18;
  --green-2: #13441f;
  --green-3: #125a27;
  --green-4: #177232;
  --green-5: #278841;
  --green-6: #429d56;
  --green-7: #63b16f;
  --green-8: #85c38d;
  --green-9: #a9d4ad;
  --green-10: #cce5ce;
  --green-11: #eff6ef;
}

// colors
@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  :root {
    --border-color: #dddde2; // sg10
    --accent: #125a27; // g3
    --accent-higher: #177232; // g4
    --background: #f6f6f7; // sg11
    --foreground: #1f2023; // sg1
    --middleground: rgb(189 189 189);
    --background-higher: #fff;
    --foreground-higher: #0e0e0f; // sg0
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --border-color: #555762; // sg4
    --accent: #85c38d; // g8
    --accent-higher: #a9d4ad; // g9
    --background: #303137; // sg2
    --foreground: #f6f6f7; // sg11
    --middleground: rgb(189 189 189);
    --background-higher: #1f2023; // sg1
    --foreground-higher: #fff;
  }
}

@media (prefers-contrast: high) {
  :root {
    --accent: rgb(251 255 29);
    --background: #000;
    --background-higher: #1f2023; // sg1
    --foreground: #fff;
    --foreground-higher: #dddde2; // sg10
  }
}

[data-theme="default"] {
  --border-color: #dddde2; // sg10
  --accent: #125a27; // g3
  --accent-higher: #177232; // g4
  --background: #f6f6f7; // sg11
  --foreground: #1f2023; // sg1
  --middleground: rgb(189 189 189);
  --background-higher: #fff;
  --foreground-higher: #0e0e0f; // sg0
}

[data-theme="dark"] {
  --border-color: #555762; // sg4
  --accent: #85c38d; // g8
  --accent-higher: #a9d4ad; // g9
  --background: #303137; // sg2
  --foreground: #f6f6f7; // sg11
  --middleground: rgb(189 189 189);
  --background-higher: #1f2023; // sg1
  --foreground-higher: #fff;
}

[data-theme="high"] {
  --accent: rgb(251 255 29);
  --background: #000;
  --foreground: #fff;
  --background-higher: #1f2023; // sg1
}

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
);
$container-max-widths: (
  sm: 420px,
  md: 720px,
  lg: 960px,
  xl: 1220px,
  xxl: 1380px
);
$enable-flex: true;
$enable-shadows: true;
$input-btn-line-height: 1.35;
$green: #3c8b54;
$link-color: $green;
$primary: $green;
$breadcrumb-bg: transparent;
$transition-time: 0.3s;
$card-bg: var(--background);
$card-header-bg: var(--background);
$table-border-color: var(--border-color);
$pagination-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-hover-bg: transparent;
$pagination-active-bg: transparent;
$pagination-active-color: var(--foreground);
$pagination-active-border-color: var(--border-color);
$pagination-border-color: var(--border-color);
$pagination-hover-border-color: var(--border-color);
$pagination-disabled-border-color: var(--border-color);
$list-group-bg: transparent;
$list-group-border-color: var(--border-color);
$card-border-color: var(--border-color);
$transition-time: 0.6s;
