// navbar
.navbar {
  padding: 0;
  margin-bottom: var(--gap);

  &-brand {
    position: relative;
    z-index: 1;

    img {
      width: 1.6rem;
      height: 1.6rem;
      vertical-align: middle;
      margin-top: -0.25rem;
      border-radius: 0.1rem;
    }
  }

  .nav-link {
    color: var(--foreground);
  }

  &-toggler {
    svg {
      fill: var(--foreground);
    }
  }
}
