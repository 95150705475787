
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  fill: currentcolor;

  .is-active > & {
    fill: var(--accent);
  }

  a & {
    fill: currentcolor;
  }

  a:hover & {
    fill: currentcolor;
  }

  &--success {
    fill: var(--success);
  }

  &--warning {
    fill: var(--warning);
  }

  &--danger {
    fill: var(--danger);
  }

  &--light {
    fill: var(--light);
  }

  &--white {
    fill: var(--white);
  }

  // sizes
  &--lg {
    width: 2rem;
    height: 2rem;
  }

  &--xl {
    width: 5rem;
    height: 5rem;
  }

  .disabled a & {
    fill: var(--middleground);
  }
}
