.wrapper {
  @include grid;

  grid-template-rows: auto 1fr auto;
  min-height: 100%;

  & > header,
  & > footer {
    @extend .container;
  }

  & > main {
    @extend .container;

    min-height: 100%;
    align-content: start;
  }
}

/* form elements in grid, + 1 last (button) minimal w/ auto */
@include media-breakpoint-up(md) {
  .form--grid {
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(var(--template-column-count), 1fr) auto;
    max-width: 100%;
  }
}

.form--grid__1 {
  --template-column-count: 1;
}

.form--grid__2 {
  --template-column-count: 2;
}

.form--grid__3 {
  --template-column-count: 3;
}

.form--grid__4 {
  --template-column-count: 4;
}

.form--grid__5 {
  --template-column-count: 5;
}

.form--grid__6 {
  --template-column-count: 6;
}

/* Title */
main > header {
  display: flex;
  gap: var(--gap);

  span {
    display: inline;
  }
}
