// Ticket types
.type {
  &--idea {
    font-style: italic;

    @extend .text-info;
  }

  &--defect {
    font-weight: bold;

    @extend .text-danger;
  }

  &--enhancement {
    @extend .text-secondary;
  }
}

/* Zu erledigen & Zeitaufzeichnung Tracat Symbol */
.zu_erledigen td:nth-child(3) a:first-child,
.ticket_list td:first-child a:first-child,
.zeitaufzeichnung td:nth-child(3) span a {
  display: inline-block;
  text-indent: -200rem;
  background: transparent url("../img/icon.svg") center center no-repeat;
  vertical-align: middle;
  width: 1.3rem;
  height: 1.5rem;
}
