// Forms

// #178 Tagged Tickets stylen
.form-check-inline {
  flex-wrap: wrap;

  .form-check-input {
    vertical-align: middle;
  }
}

.input-group {
  margin-right: 0.5rem;
}
